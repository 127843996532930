import React from 'react';
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../store/hooks.ts";
import {selectUser} from "../../../store/auth.ts";
import ActionButton from "../../../../_mesomb/partials/components/ActionButton.tsx";
import {setAuthUrl} from "../core/AuthHelpers.ts";
import {toast, ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet";

function VerifiedEmailRequired() {
  const {t} = useTranslation()
  const currentUser = useAppSelector(selectUser);

  return (
    <>
      <div className="card rounded-3 w-md-550px">
        <div className="card-body p-10 p-lg-20">
          <h1>{t('Verify Your Email Address')}</h1>

          {/*{% url 'account_email' as email_url %}*/}

          <p>{t('Email_required_access_part_message')}</p>

          <p>{t('Email_required_access_part_email_resent_message')}</p>

          <ActionButton
            title={t('Resent Email Confirmation')}
            action={{
              path: setAuthUrl('account/email'),
              method: 'put',
            }}
            data={{email: currentUser!.email}}
            className={'btn btn-light-primary mt-2 fw-bold flex-shrink-0'}
            onFinish={() => {
              toast.success(t('Verification email has been successfully sent!'))
            }}
          />
        </div>
        <ToastContainer/>
      </div>
      <Helmet>
        <title>{t('Verified Email')}</title>
      </Helmet>
    </>
  );
}

export default VerifiedEmailRequired;
