import {Link} from 'react-router-dom'
import {Languages} from '../header-menus/Languages'
import {useAppDispatch, useAppSelector} from "../../../../app/store/hooks.ts";
import {selectUser, setUser} from "../../../../app/store/auth.ts";
import HIcon from "../../../../app/modules/payment/components/HIcon.tsx";
import HStatus from "../../components/HStatus.tsx";
import {camelize} from "../../../utils";
import {useTranslation} from "react-i18next";
import {setAuthUrl} from "../../../../app/modules/auth";
import axios from "axios";

const UserMenu = () => {
  const dispatch = useAppDispatch();
  // const {logout} = useAuth()
  const currentUser = useAppSelector(selectUser);
  const {t} = useTranslation()
  const onSignOut = async () => {
    try {
      await axios.delete(setAuthUrl('auth/session'))
    } catch (e) {}
    dispatch(setUser(undefined));
    location.href = '/auth/login'
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {currentUser && <HIcon image={currentUser?.avatar?.path} size={40} text={currentUser?.full_name} />}

          {/* begin::Username */}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <span className={'me-1'}>{currentUser?.full_name}</span>
              <HStatus label={camelize(currentUser.status || '')} status={currentUser.status} />
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'></div>
      {/* end::Menu separator */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/account/profile'} className='menu-link px-5'>
          {t('My Profile')}
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/account/team'} className='menu-link px-5'>
          {t('My Team')}
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/account/account'} className='menu-link px-5'>
          {t('My Account')}
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'> </div>
      {/* end::Menu separator */}

      <Languages languageMenuPlacement='right-end' />

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/account/security'} className='menu-link px-5'>
          {t('Security')}
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/account/developer'} className='menu-link px-5'>
          {t('Developer')}
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      {/*<div className='menu-item px-5'>*/}
      {/*  <Link to={'/account/preferences'} className='menu-link px-5'>*/}
      {/*    {t('Preferences')}*/}
      {/*  </Link>*/}
      {/*</div>*/}
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <a onClick={onSignOut} className='menu-link px-5 sign-out'>
          {t('Sign Out')}
        </a>
      </div>
    </div>
  )
}

export {UserMenu}
