import React, {Suspense} from "react";
import {DisableSidebar} from "../../layout/core";
import TopBarProgress from "react-topbar-progress-indicator";
import {getCSSVariableValue} from "../../assets/ts/_utils";

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export default SuspensedView;