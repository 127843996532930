import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {ApplicationModel} from "../modules/payment/core/_models.ts";

export interface AuthSliceState {
  applications: Array<ApplicationModel>
  application?: ApplicationModel
}

const initialState: AuthSliceState = {
  applications: [],
  application: undefined,
}

export const applicationSlice = createAppSlice({
  name: 'application',
  initialState,
  reducers: create => ({
    setApplications: create.reducer((state, action: PayloadAction<Array<ApplicationModel>>) => {
      state.applications = action.payload
    }),
    setApplication: create.reducer((state, action: PayloadAction<ApplicationModel | undefined>) => {
      state.application = action.payload
    }),
  }),
  selectors: {
    selectApplications: application => application.applications,
    selectApplication: application => application.application,
  },
});

export const { setApplications, setApplication } =
  applicationSlice.actions

export const { selectApplications, selectApplication } = applicationSlice.selectors
