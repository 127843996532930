import React, {useEffect, useRef} from 'react'
import {Outlet, useLocation, useNavigation} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
// import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
// import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {useAppDispatch, useAppSelector} from "../../app/store/hooks.ts";
import {selectUser, setUser} from "../../app/store/auth.ts";
import VerifiedEmailRequired from "../../app/modules/auth/components/VerifiedEmailRequired.tsx";
import RequestProfileVerification from "../../app/modules/account/components/RequestProfileVerification.tsx";
import TopBarProgress from "react-topbar-progress-indicator";
import axios from "axios";
import {debounce} from "../utils";
import {selectTerms} from "../../app/store/terms.ts";
import AcceptTermsAndConditions from "../../app/modules/account/components/AcceptTermsAndConditions.tsx";
import {selectAnnouncements, setAnnouncements} from "../../app/store/announcements.ts";
import Markdown from "markdown-to-jsx";
import {KTIcon} from "../helpers";
import {AnnouncementModel, mapAnnouncementModel} from "../../app/modules/payment/core/_models.ts";

const MasterLayout = ({product}) => {
  const lastPageDate = useRef(new Date().getTime());
  const lastPage = useRef();
  const location = useLocation()
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const terms = useAppSelector(selectTerms);
  const announcements = useAppSelector(selectAnnouncements);


  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500);

    debounce(() => {
      const params = {}
      if (currentUser) {
        params.preferred_locale = currentUser.locale;
        params.page_language = currentUser.locale;
        params.email = currentUser.email;
      }
      if (lastPage.current) {
        params.page_referrer = lastPage.current;
        lastPage.current = window.location.href;
      }
      mt('send', 'pageview', params);
    }, 200)()
  }, [location.key])

  useEffect(() => {
    axios.get('profile/').then((ret) => {
      if (ret?.data) {
        dispatch(setUser(ret.data))
      }
    });
  }, [])
  const navigation = useNavigation()

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        {currentUser!.status != 'VERIFIED' && <RequestProfileVerification />}
        {terms && terms.accepted !== true && <AcceptTermsAndConditions terms={terms} />}
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault product={product}/>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper/>

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                {currentUser!.email_verified ? (
                  <>
                    {announcements.map<AnnouncementModel>(a => mapAnnouncementModel(a)).filter(a => (a.publishStart < new Date() && (!a.publishEnd || a.publishEnd > new Date()))).map(a => (
                      <div
                        className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-4'>
                        {/*<KTIcon iconName='information-5' className='fs-2tx text-warning me-4'/>*/}
                        <div className='d-flex flex-stack flex-grow-1'>
                          <div className='fw-bold'>
                            <h4 className='text-gray-800 fw-bolder'>{a.title}</h4>
                            <div className='fs-6 text-gray-600'>
                              <Markdown>{a.content}</Markdown>
                            </div>
                          </div>
                        </div>
                        {a.dismissalType > 1 && (
                          <button
                            type="button"
                            onClick={() => {
                              axios.patch(`announcements/`, {action: 'dismiss', announcement: a.id}).then(() => {
                                dispatch(setAnnouncements(announcements.filter(item => item.id != a.id)))
                              })
                            }}
                            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                            data-bs-dismiss="alert">
                            <KTIcon iconName={'abstract-11'} className='fs-2'/>
                          </button>
                        )}
                        {/*<button type="button"
                                className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                                data-bs-dismiss="alert">
                          <i className="ki-duotone ki-cross fs-1 text-light"><span className="path1"></span><span
                            className="path2"></span></i>
                        </button>*/}
                      </div>
                    ))}
                    <Outlet/>
                  </>
                ) : <VerifiedEmailRequired/>}
                {navigation.state === 'loading' && <TopBarProgress/>}
              </Content>
            </div>
            <Footer/>
          </div>
          {/*<Sidebar />*/}
        </div>
      </div>

      {/* begin:: Drawers */}
      {/*<ActivityDrawer />*/}
      {/*<RightToolbar />*/}
      {/*<DrawerMessenger />*/}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/*<InviteUsers />*/}
      {/*<UpgradePlan />*/}
      {/* end:: Modals */}
      <ScrollTop/>
    </PageDataProvider>
  )
}

export {MasterLayout}
