import React from 'react';
import I18n from "../../../../_mesomb/i18n";
import i18n from "../../../../_mesomb/i18n";
import * as Yup from "yup";
import FormBuilder from "../../../../_mesomb/partials/form/FormBuilder.tsx";
import ModalFormBuilder from "../../../../_mesomb/partials/components/ModalFormBuilder.tsx";
import {FormFieldProps} from "../../../../_mesomb/partials/form/FormField.tsx";

export const fields: Array<FormFieldProps> = [
  {
    type: 'full_name',
    name: 'full_name',
    label: i18n.t('Full Name'),
    // placeholder: i18n.t('The public name of your service')
  },
  {
    name: 'gender',
    label: I18n.t('Gender'),
    type: 'radio',
    options: [
      {value: 'MAN', label: i18n.t('Man')},
      {value: 'WOMAN', label: i18n.t('Woman')},
    ],
    orientation: 'horizontal',
  },
  {
    name: 'town',
    label: I18n.t('Town'),
    type: 'text',
  },
  {
    type: 'group',
    items: [
      {
        name: 'country',
        type: 'country',
        label: i18n.t('Country'),
      },
      {
        name: 'region',
        label: i18n.t('Region'),
        type: 'text',
      },
    ],
  },
  {
    name: 'avatar',
    label: i18n.t('Your Picture'),
    type: 'dropzone',
    placeholder: i18n.t('Upload a selfie'),
  },
  {
    type: 'date',
    name: 'birth_date',
    label: 'Birth Date',
    col: 2,
  },
  {
    type: 'select',
    name: 'id_card_type',
    label: 'ID Card Type',
    options: [
      {value: 'NIC', label: i18n.t('National Identity Card')},
      {value: 'PASSPORT', label: i18n.t('Passport')},
      {value: 'TEMP_ID', label: i18n.t('Temporary Identity')},
      {value: 'DRIVER_LICENCE', label: i18n.t('Driver Licence')},
      {value: 'VOTER_CARD', label: i18n.t('Voter Card')},
    ],
    col: 2,
  },
  {
    type: 'text',
    name: 'id_card_number',
    label: 'ID Card Number',
    col: 2,
  },
  {
    type: 'date',
    name: 'id_card_expiry',
    label: 'ID Card Expiry Date',
    col: 2,
  },
  {
    type: 'group',
    items: [
      {
        name: 'id_card_front',
        label: i18n.t('ID Front Card'),
        type: 'dropzone',
        col: 2,
        placeholder: i18n.t('Upload the front of your ID card')
      },
      {
        name: 'id_card_back',
        label: i18n.t('ID Back Card'),
        type: 'dropzone',
        required: false,
        col: 2,
        placeholder: i18n.t('Upload the back of your ID card')
      },
    ],
    col: 2,
  }
];

export const formSchema = Yup.object().shape({
  full_name: Yup.object({
    last_name: Yup.string().required(i18n.t('At least the last name is required')),
    first_name: Yup.string()
  }),
  gender: Yup.string().required(i18n.t('Field_is_required')),
  town: Yup.string().required(i18n.t('Field_is_required')),
  region: Yup.string().required(i18n.t('Field_is_required')),
  country: Yup.string().required(i18n.t('Field_is_required')),
  avatar: Yup.string().required(i18n.t('Field_is_required')),
  birth_date: Yup.date().max(new Date(), i18n.t('Invalid_birth_date')).required(i18n.t('Field_is_required')),
  id_card_type: Yup.string().required(i18n.t('Field_is_required')),
  id_card_number: Yup.string().required(i18n.t('Field_is_required')).matches(/^[A-Z0-9]+$/, i18n.t('Invalid_id_card_number')),
  id_card_expiry: Yup.date().min(new Date(), i18n.t('Invalid_card_expiry_date')).required(i18n.t('Field_is_required')),
  id_card_front: Yup.string().required(i18n.t('Field_is_required')),
  id_card_back: Yup.string().required(i18n.t('Field_is_required')),
  // b_party: Yup.string().matches(/^[0-9]{8,9}$/, i18n.t('Invalid_phone_number')).required(i18n.t('Field_is_required')),
})

function ProfileForm({asModal, show, handleClose, action, onSubmit, profile, ...props}) {
  const Component = asModal ? ModalFormBuilder : FormBuilder
  fields[4].file = profile.avatar;
  fields[9].items[0].file = profile.id_card_front;
  fields[9].items[1].file = profile.id_card_back;
  return (
    <Component
      show={show}
      handleClose={handleClose}
      fields={fields}
      formSchema={formSchema}
      className={''}
      action={action}
      onSubmit={onSubmit}
      initialValues={{
        full_name: {
          first_name: profile.first_name,
          last_name: profile.last_name,
        },
        gender: profile.gender,
        town: profile.town,
        region: profile.region,
        country: profile.country,
        avatar: profile.avatar?.id,
        birth_date: profile.birth_date,
        id_card_type: profile.id_card_type,
        id_card_number: profile.id_card_number,
        id_card_expiry: profile.id_card_expiry,
        id_card_front: profile.id_card_front?.id,
        id_card_back: profile.id_card_back?.id,
      }}
      nbrCols={2}
      {...props}
    />
  );
}

export default ProfileForm;
