export interface AccountModel {
  identifier: number;
  name: string;
  service_key: string;
  image: string;
  out_rate: number;
  in_rate: number;
}

export interface UploadedFile {
  id: number;
  filename: string;
  path: string;
  size: number;
  width?: number;
  height?: number;
}

export interface ApplicationModel {
  pk: number;
  url?: string;
  key: string;
  name: string;
  logo?: UploadedFile;
  last_activity?: Date;
  balance: number;
  access: {
    is_admin: boolean;
    is_owner: boolean;
    is_developer: boolean;
    is_finance: boolean;
    roles: Array<string>;
  }
  balances: Array<{
    service_name: string;
    available_value: number;
    currency: string;
  }>;
  stats?: {
    all_time_debit: number;
    all_time_credit: number;
  };
  countries: Array<string>;
  accounts: Array<AccountModel>;
  dev_key: string;
  is_live?: boolean;
}

export const mapApplicationModel = (data: any): ApplicationModel | undefined => {
  if (!data) {
    return undefined;
  }
  return {
    ...data,
    last_activity: new Date(data.last_activity),
    access: data.access ? {
      ...data.access,
      is_admin: data.access.roles.includes('admin') || data.access.roles.includes('owner'),
      is_owner: data.access.roles.includes('owner'),
      is_developer: data.access.roles.includes('developer') || data.access.roles.includes('admin') || data.access.roles.includes('owner'),
      is_finance: data.access.roles.includes('fund_management') || data.access.roles.includes('admin') || data.access.roles.includes('owner'),
    } : undefined,
  }
}


export interface WebhookLog {
  status: string;
  url: string;
  topic: string;
  created: Date;
}

export interface AutomaticDeposit {
  id: string;
  recurrence: string;
  next_run?: Date;
  status: string;
  b_party: string;
  last_run?: Date;
  nbr_runs?: number;
  active: boolean;
  amount?: number;
}

export interface Transaction {
  id: string;
  status: string;
  amount: number;
  trxamount: number;
  direction: -1|1;
  currency: string;
  date: Date;
  reference: string;
  description: string;
  type: string;
  account: string;
  balance: number;
  balance_currency: string;
  ts: Date;
  fin_trx_id: string;
  name?: string;
  application: number;
  b_party: string;
}

export interface Product {
  id: string;
  image?: string;
  name: string;
  description?: string;
  default_price?: number;
  currency?: string;
  created_at: Date;
  updated_at: Date;
  sells?: number;
  last_sell?: Date;
  default_price_currency?: string;
  image_url?: string;
}

export interface Customer {
  id: string;
  name?: string;
  email?: string;
  phone?: string;
  town?: string;
  region?: string;
  country?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  address1?: string;
  address2?: string;
  amount: number;
  total: number;
  last_activity?: Date;
  created_at: Date;
  fullName?: string;
  displayAddress?: string;
  address?: string;
}

export const mapCustomer = (data: any): Customer => {
  let fullName;
  if (!data.first_name && !data.last_name) {
    fullName = data.email || data.phone;
  } else {
    fullName = `${data.first_name} ${data.last_name}`;
  }
  const location = `${data.town || ''}, ${data.region || ''}, ${data.country || ''}`.trim();
  const address = `${data.address1 || ''}, ${data.address2 || ''}`.trim();
  const displayAddress = `${address}\n${location}`.trim();
  return {
    ...data,
    created_at: new Date(data.created_at),
    last_activity: new Date(data.last_activity),
    address,
    fullName,
    displayAddress,
  }
}

export interface PaymentLink {
  id: string;
  url: string;
  status: string;
  name?: string;
  price?: number;
  created_at: Date;
  application: ApplicationModel;
}

export interface TeamMember {
  id: string;
}

export interface Session {
  id: string;
  browser: string;
  os: string;
  ip: string;
  location: string;
  last_activity: Date;
  created: Date;
  city?: string;
  country?: string;
}

export interface ScheduledTransaction {
  id: string;
  status: string;
  amount: number;
  recurrence: string;
  next_run?: Date;
  last_run?: Date;
  nbr_runs?: number;
  b_party: string;
  operation: {
    icon: string;
    name: string;
  }
  details?: {
    merchant: string;
  }
}

export const get_name = link => {
  if (link.name) return link.name

  if (link.type === 'PAYMENT') return link.title

  const nbr_products = link.products.length
  return `${link.products[0]?.product.name} ${nbr_products > 1 ? `+${nbr_products - 1}` : ''}`
}


export interface AnnouncementModel {
  content: string;
  dismissalType: number;
  id: number;
  publishEnd?: Date;
  publishStart: Date;
  title: string;
}

export const mapAnnouncementModel = (data: Record<string, any>): AnnouncementModel => {
  return {
    content: data.content,
    dismissalType: data.dismissal_type,
    publishEnd: data.publish_end ? new Date(data.publish_end) : undefined,
    publishStart: new Date(data.publish_start),
    title: data.title,
    id: data.id,
  }
}
