import React, {useRef, useState} from 'react';
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {KTIcon} from "../../../../_mesomb/helpers";
import {fields as profileFields, formSchema as profileFormSchema} from "./ProfileForm.tsx";
import {fields as businessFields, formSchema as businessFormSchema} from "./BusinessForm.tsx";
import {fields as bankAccountFields, formSchema as bankAccountFormSchema} from "./BankAccountForm.tsx";
import {useAppDispatch, useAppSelector} from "../../../store/hooks.ts";
import {selectUser, setUser} from "../../../store/auth.ts";
import {Form, Formik, FormikValues} from "formik";
import FormField from "../../../../_mesomb/partials/form/FormField.tsx";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import axios from "axios";
import {processErrorResponse} from "../../../../_mesomb/utils";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";

const profileVerificationSchema = [profileFormSchema, businessFormSchema, bankAccountFormSchema]
const fields = [profileFields, businessFields, bankAccountFields]

function ProfileVerificationWizard({show, handleClose}) {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const currentUser = useAppSelector(selectUser)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [step, setStep] = useState(0)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const dispatch = useAppDispatch();
  const initials = [
    {
      full_name: {
        first_name: currentUser!.first_name,
        last_name: currentUser!.last_name,
      },
      gender: currentUser!.gender,
      town: currentUser!.town,
      region: currentUser!.region,
      country: currentUser!.country,
      avatar: currentUser!.avatar?.id,
      birth_date: currentUser!.birth_date,
      id_card_type: currentUser!.id_card_type,
      id_card_number: currentUser!.id_card_number,
      id_card_expiry: currentUser!.id_card_expiry,
      id_card_front: currentUser!.id_card_front?.id,
      id_card_back: currentUser!.id_card_back?.id,
    },
    {
      legal_form: currentUser!.business?.legal_form || 'SARL',
      name: currentUser!.business?.name,
      contact_email: currentUser!.business?.contact_email,
      contact_phone: currentUser!.business?.contact_phone,
      trade_register_number: currentUser!.business?.trade_register_number,
      taxpayer_card_number: currentUser!.business?.taxpayer_card_number,
      logo: currentUser!.business?.logo?.id,
      trade_register: currentUser!.business?.trade_register?.id,
      taxpayer_card: currentUser!.business?.taxpayer_card?.id,
    },
    {
      bank_rib: {
        bank_code: '',
        agence_code: '',
        account_number: '',
        rib_key: '',
      },
      name: '',
      bank: ''
    }
  ]

  const prevStep = () => {
    setStep(step - 1)

    // setCurrentSchema(createAccountSchemas[stepper.currentStepIndex - 1])

    setSubmitButton(step == 3)
  }

  const skipStep = () => {
    setStep(step + 1)

    // setCurrentSchema(createAccountSchemas[stepper.currentStepIndex - 1])

    setSubmitButton(step == 3)
  }

  const submitStep = (values: any, actions: FormikValues) => {
    const steps = {0: 'profile-verifications', 1: 'business-verifications', 2: 'bank-verifications'}
    if (step != 3) {
      actions.setSubmitting(true)
      axios.put(`/profile/?action=${steps[step]}`, {...values, ...(values.full_name || {}), ...(values.bank_rib || {})}).then(({data}) => {
        actions.setSubmitting(false)
        dispatch(setUser(data))
        setStep(step + 1)
      }).catch(error => {
        actions.setSubmitting(false)
        processErrorResponse(error, actions.setErrors)
      })
    } else {
      // stepper.goto(1)
      actions.resetForm()
    }

    setSubmitButton(step === 3)
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{t('Verify your Profile')}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross'/>
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className={clsx('stepper-item', {current: step == 0})} data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{t('Personal Info')}</h3>
            </div>

            <div className={clsx('stepper-item', {current: step == 1})} data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{t('Business Info')}</h3>
            </div>

            <div className={clsx('stepper-item', {current: step == 2})} data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{t('Bank Account')}</h3>
            </div>

            <div className={clsx('stepper-item', {current: step == 3})} data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>{t('Confirmation')}</h3>
            </div>
          </div>
          <Formik validationSchema={profileVerificationSchema[step]} initialValues={initials[step]}
                  onSubmit={submitStep} key={`step-${step}`}>
            {({isSubmitting, isValid, touched, errors, values}) => {
              if (step == 3) {
                return (
                  <div className="w-100">
                      <div className="pb-12 text-center">
                          <h1 className="fw-bold text-dark mb-6">{t('Profile Submitted')}!</h1>
                          <div className="fw-semibold text-muted fs-4">
                            {t('Your profile has been successfully submitted for activation.')} <br/> {t('Our team will validate it and you will be notified.')}
                          </div>
                      </div>

                      <div className="d-flex flex-center pb-20">
                          <button onClick={() => {
                            handleClose()
                            navigate('/account/profile')
                          }}
                            className="btn btn-lg btn-light-primary">{t('Go to Profile')}</button>
                      </div>
                  </div>
                )
              }

              return (
                <Form className='mx-auto w-100' id='kt_create_account_form' placeholder={undefined}>
                  <div className='current row' data-kt-stepper-element='content'>
                    {Array(step > 1 ? 1 : 2).fill(0).map((item, index) => (
                      <div className={step > 1 ? 'col-sm-12' : `col-sm-6`}>
                        {fields[step].filter(f => (f.col || 1) === index + 1).map(field => {
                          if (field.type === 'group') {
                            return (
                              <div className='row'>
                                {field.items.map(f => {
                                  if (step == 0 && ['avatar', 'id_card_front', 'id_card_back'].includes(f.name)) {
                                    f.file = currentUser![f.name]
                                  }
                                  return (
                                    <div key={f.name} className={'col-sm-6'}>
                                      <FormField
                                        touched={touched}
                                        errors={errors}
                                        {...f}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          }
                          if (step == 0 && ['avatar', 'id_card_front', 'id_card_back'].includes(field.name)) {
                            field.file = currentUser![field.name]
                          }
                          if (currentUser.business && step == 1 && ['logo', 'trade_register', 'taxpayer_card'].includes(field.name)) {
                            field.file = currentUser.business![field.name]
                          }
                          return (
                            <FormField
                              key={field.name}
                              touched={touched}
                              errors={errors}
                              {...field}
                            />
                          )
                        })}
                      </div>
                    ))}
                  </div>

                  <div className='d-flex flex-stack pt-15'>
                    <div className='mr-2'>
                      {step > 0 && (
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                        >
                          <KTIcon iconName='arrow-left' className='fs-4 me-1'/>
                          {t('Back')}
                        </button>
                      )}
                    </div>

                    <div>
                      {step > 0 && (
                        <button
                          onClick={skipStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                        >
                          {t('Skip')}
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0'/>
                        </button>
                      )}

                      <ButtonIndicator
                        type={'submit'}
                        label={(
                          <>
                            {isSubmitButton ? t('Submit') : t('Continue')} <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0'/>
                          </>
                        )}
                        loading={isSubmitting}
                        marginTop={0}
                      />
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>
  );
}

export default ProfileVerificationWizard;
