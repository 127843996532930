import React from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {setAuth, setUser} from "../../../store/auth.ts";
import {useAppDispatch} from "../../../store/hooks.ts";
import {setAuthUrl} from "../core/AuthHelpers.ts";

function EmailVerification() {
  const {key} = useParams()
  const dispatch = useAppDispatch();

  axios.post(setAuthUrl('auth/email/verify'), {key}).then(async ({data}) => {
    dispatch(setAuth(data.meta))
    const {data: profile} = await axios.get('profile/')
    dispatch(setUser(profile))
    window.location.href = '/auth/login'
  })
  return (
    <div></div>
  );
}

export default EmailVerification;
