import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {AccountModel} from "../modules/payment/core/_models.ts";

export interface ConfigsSliceState {
  configs: {
    providers: Array<Record<string, any>>;
    accounts: Array<AccountModel>;
  }
}

const initialState: ConfigsSliceState = {
  configs: localStorage.getItem('configs') ? JSON.parse(localStorage.getItem('configs') as string) : { providers: [], accounts: [] }
}

export const configsSlice = createAppSlice({
  name: 'configs',
  initialState,
  reducers: create => ({
    setConfigs: create.reducer((state, action: PayloadAction<Record<string, any>>) => {
      state.configs = action.payload
      localStorage.setItem('configs', JSON.stringify(action.payload))
    }),
  }),
  selectors: {
    selectConfigs: configs => configs.configs,
  },
});

export const { setConfigs } =
  configsSlice.actions

export const { selectConfigs } = configsSlice.selectors
