import React, {useEffect} from 'react';
import i18n from "../../../_mesomb/i18n";
import {toAbsoluteStatic} from "../../../_mesomb/helpers";
import {useRouteError} from "react-router-dom";

function ErrorPage({error, feedback}) {
  let errorRoute;
  try {
    errorRoute = useRouteError();
  } catch (e) {
    // console.log(e);
  }

  useEffect(() => {
    const widget = feedback?.createWidget();
    widget?.appendToDom();

    return () => {
      widget?.removeFromDom();
    }
  }, []);
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              {/* begin::Title */}
              <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>{i18n.t('System Error')}</h1>
              {/* end::Title */}

              {/* begin::Text */}
              <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                {i18n.t('Something went wrong! Please try again later.')}
              </div>

              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{(error || errorRoute)?.message}</div>
              </div>
              {/* end::Text */}

              {/* begin::Illustration */}
              <div className='mb-11'>
                <img
                  src={toAbsoluteStatic('/static/images/auth/500-error.png')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
                <img
                  src={toAbsoluteStatic('/static/images/auth/500-error-dark.png')}
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                />
              </div>
              {/* end::Illustration */}

              {/* begin::Link */}
              <div className='mb-0'>
                <a href='/dashboard' className='btn btn-sm btn-primary'>
                  {i18n.t('Return Home')}
                </a>
              </div>
              {/* end::Link */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
