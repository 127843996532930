import React from 'react';
import {Form, Formik} from "formik";
import FormField, {FormFieldProps} from "./FormField.tsx";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import axios from "axios";
import {processErrorResponse} from "../../utils";

export interface FormBuilderProps {
  fields: Array<FormFieldProps>;
  formSchema: any;
  className?: string;
  action?: { path: string, method?: 'post' | 'delete' | 'patch', responseType?: 'json' | 'text' };
  onSubmit?: (data: any, values: any) => void;
  initialValues: any;
  nbrCols?: number;
}

function FormBuilder({fields, formSchema, className = 'card', action, onSubmit, initialValues, nbrCols = 1}: FormBuilderProps) {
  const {t} = useTranslation();
  return (
    <Formik validationSchema={formSchema} initialValues={initialValues} onSubmit={(values, actions) => {
      actions.setSubmitting(true)
      const functions = {post: axios.post, delete: axios.delete, patch: axios.patch, put: axios.put}
      if (action) {
        functions[action.method || 'post'](action.path, {...values, ...(values.full_name || {}), ...(values.bank_rib || {})}, {responseType: action.responseType || 'json'}).then(({data}) => {
        actions.setSubmitting(false)
        onSubmit && onSubmit(data, values)
        }).catch((error) => {
          actions.setSubmitting(false)
          processErrorResponse(error, actions.setErrors)
        })
      } else {
        onSubmit && onSubmit(values, values)
        actions.setSubmitting(false)
      }
    }}>
      {({isSubmitting, isValid, touched, errors, values}) => {
        return (
          <Form className={clsx('row', className)} id="kt_create_application" method="post"
                placeholder={undefined}>
            {Array(nbrCols).fill(0).map((item, index) => (
              <div className={`col-sm-${12 / nbrCols}`} key={`col-${index}`}>
                {fields.filter(f => (f.col || 1) === index + 1).map(field => {
                  if (field.type === 'group') {
                    return (
                      <div className='row'>
                        {field.items.map(f => (
                          <div key={f.name} className={'col-sm-6'}>
                            <FormField
                              touched={touched}
                              errors={errors}
                              {...f}
                            />
                          </div>
                        ))}
                      </div>
                    )
                  }
                  return (
                    <FormField
                      key={field.name}
                      touched={touched}
                      errors={errors}
                      {...field}
                    />
                  )
                })}
              </div>
            ))}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={isSubmitting || !isValid || !touched}
              >
                {!isSubmitting && t('Submit')}
                {isSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>{t('Please_wait')}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>)}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
}

export default FormBuilder;
