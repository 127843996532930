import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {AnnouncementModel} from "../modules/payment/core/_models.ts";

export interface AnnouncementsSliceState {
  announcements: AnnouncementModel[];
}

const initialState: AnnouncementsSliceState = {
  announcements: []
}

export const announcementsSlice = createAppSlice({
  name: 'announcements',
  initialState,
  reducers: create => ({
    setAnnouncements: create.reducer((state, action: PayloadAction<Record<string, any>>) => {
      state.announcements = action.payload
    }),
  }),
  selectors: {
    selectAnnouncements: announcements => announcements.announcements,
  },
});

export const { setAnnouncements } =
  announcementsSlice.actions

export const { selectAnnouncements } = announcementsSlice.selectors
