import {FC} from 'react'
import {KTIcon} from '../../../helpers'
import {UserMenu} from '../user-menu/UserMenu'
import HIcon from "../../../../app/modules/payment/components/HIcon.tsx";
import {useAppSelector} from "../../../../app/store/hooks.ts";
import {selectUser} from "../../../../app/store/auth.ts";

const AsideUserMenu: FC = () => {
  const currentUser = useAppSelector(selectUser);

  return (
    <>
      <div className='d-flex flex-stack' id={'user-profile-menu'}>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          {currentUser && <HIcon image={currentUser?.avatar?.path} size={40} text={currentUser?.full_name} />}
          <div className='ms-2'>
            <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1'>
              {currentUser?.full_name}
            </a>
            <span className='text-muted fw-bold d-block fs-7 lh-1'>{currentUser?.email}</span>
          </div>
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className='ms-1'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-end'
          >
            <KTIcon iconName='setting-2' className='fs-1' />
          </div>
          <UserMenu />
        </div>
        {/* end::User menu */}
      </div>
    </>
  )
}

export {AsideUserMenu}
